<template>
  <div>
    <!-- <div>{{ column }}</div> -->
    <date-picker
      :config="dateOptions"
      v-model="date"
      @dp-change="updateDate"
    />
  </div>  
</template>

<script>
  import moment from "moment";
  import datePicker from 'vue-bootstrap-datetimepicker';
  import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

  export default {
    components: {
      datePicker
    },
    props: {
      column: {
        type: Object,
        required: true,
      },
      updateFilters: {
        type: Function,
        required: true,
      },
    },
    data: () => {
      return {
        date: null,
        dateOptions: {
          format: 'DD.MM.YYYY',
          useCurrent: true,
          showClear: true,
          showClose: true,
          locale: 'ru',
        }
      }
    },
    methods: {
      updateDate() {
        const date = moment(this.date, 'DD.MM.YYYY').utcOffset(0, true).startOf('day').format();
        this.updateFilters(this.column, date)
      }
    },
  }
</script>

<style scoped>

</style>
