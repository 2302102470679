var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.grid}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"4","lg":"3","sm":"12"}},[(_vm.extendedFilterSchema && Object.keys(_vm.extendedFilterSchema).length > 0)?_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":_vm.updateExtendedFilterVisibility}},[_c('span',{staticClass:"fa fa-search"}),_vm._v(" "+_vm._s(_vm.$t('extendedSearch'))+" ")]):_vm._e()],1),(_vm.addRoute)?_c('b-col',{staticClass:"pull-right",attrs:{"md":"4","offset-md":"6","lg":"3","sm":"12"}},[_c('router-link',{attrs:{"to":{name: _vm.addRoute, params: _vm.addRouteParams}}},[_c('b-button',{attrs:{"block":"","variant":"success"}},[_c('span',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.addText))])],1)],1):_vm._e(),(_vm.showExtendedFilter)?_c('b-col',{attrs:{"sm":"12"}},[_c('br'),_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('strong',[_vm._v(_vm._s(_vm.$t('extendedSearch')))])]),_c('vue-form-generator',{attrs:{"schema":_vm.extendedFilterFormFinalSchema,"model":_vm.extendedFilterForm,"options":{
            validateAfterLoad: false,
            validateAfterChanged: true,
          }}})],1)],1):_vm._e()],1),_c('br'),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"12"}},[_c('vue-good-table',{ref:"table",attrs:{"mode":"remote","totalRows":_vm.grid.totalRecords,"isLoading":_vm.grid.isLoading,"row-style-class":_vm.rowStyleClassFn,"pagination-options":{
          perPage: _vm.perPage,
          enabled: true,
          perPageDropdownEnabled: !_vm.perPage,
          dropdownAllowAll: false,
          setCurrentPage: _vm.grid.serverParams.page,
          nextLabel: _vm.$t('next'),
          prevLabel: _vm.$t('prev'),
          rowsPerPageLabel: _vm.$t('rowsPerPage'),
          allLabel: _vm.$t('allItemPerPage'),
        },"rows":_vm.grid.rows,"columns":_vm.translatedColumns,"select-options":{ enabled: this.enableCheckboxes }},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actions')?_c('span',[(props.column.actions)?_c('span',_vm._l((props.column.actions),function(action){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(!action.action.visibilityResolver || action.action.visibilityResolver(props.row)),expression:"!action.action.visibilityResolver || action.action.visibilityResolver(props.row)"}],key:action.field,staticStyle:{"margin-right":"3px"}},[(action.action.type==='route')?_c('router-link',{attrs:{"to":{
                   name: action.action.name ? action.action.name : action.action.nameResolver(_vm.actionParams),
                   params: action.action.paramsResolver(props.row),
                 }}},[_c('span',{class:action.class ? action.class : action.classCallback(props.row)},[_vm._v(" "+_vm._s(action.label)+" ")])]):_vm._e(),(action.action.type==='callback')?_c('span',{staticStyle:{"color":"#20a8d8","cursor":"pointer"}},[_c('span',{class:action.class ? action.class : action.classCallback(props.row),on:{"click":function($event){$event.preventDefault();return action.action.callbackFunction(_vm.grid.rows, props.row, _vm.actionParams, _vm.loadItems, _vm.$eventBus)}}},[_vm._v(" "+_vm._s(action.label)+" ")])]):_vm._e()],1)}),0):_c('span',[_vm._v(" "+_vm._s(_vm.$t('operationsAreNotResolved'))+" ")])]):_c('div',[(props.column.formatRowValue)?_c('span',[(props.column.html)?_c('span',{domProps:{"innerHTML":_vm._s(props.column.formatFn(props.row))}}):_c('span',[_vm._v(_vm._s(props.column.formatFn(props.row)))])]):_c('span',{domProps:{"innerHTML":_vm._s(props.formattedRow[props.column.field])}})])]}},{key:"column-filter",fn:function(ref){
                 var column = ref.column;
                 var updateFilters = ref.updateFilters;
return [(column.filterOptions && column.filterOptions.enabled)?_c('div',{staticStyle:{"width":"100%"}},[(column.filterOptions.date)?_c('div',{staticStyle:{"background":"#fff"}},[_c('date-picker',{attrs:{"column":column,"updateFilters":updateFilters}})],1):(column.filterOptions.filterDropdownItems && column.filterOptions.filterDropdownItems.length > 0)?_c('b-form-select',{staticStyle:{"width":"100%"},attrs:{"options":[{value: null, text: column.filterOptions.placeholder}].concat(column.filterOptions.filterDropdownItems)},on:{"input":function (value) { return updateFilters(column, value); }}}):_c('b-form-input',{staticStyle:{"width":"100%"},on:{"input":function (value) { return updateFilters(column, value); }}})],1):_vm._e()]}}])},[_c('template',{slot:"loadingContent"},[_c('div',{staticStyle:{"margin":"auto"}},[_c('clip-loader',{attrs:{"loading":_vm.grid.isLoading,"color":"#20a8d8","height":200,"width":200}})],1)])],2)],1)],1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }